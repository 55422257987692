import React from 'react';
import { ICartLineProps } from './cartlineitem.component';
import { ICartLineProps as ICheckoutCartLineProps } from './cartlineitem-variant.component';
import { getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';

import { IActionContext } from '@msdyn365-commerce/core';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy';


interface IMasterProdDisplay {
    masterProdId: number,
    variantName: string,
    props: ICartLineProps | ICheckoutCartLineProps
}
export class MasterProdDisplay extends React.Component<IMasterProdDisplay, { prodName: string }> {
    constructor(props: IMasterProdDisplay) {
        super(props);
        this.state = {
            prodName: ''
        };
    }
    public componentDidMount() {
        const masterProductInput = new SelectedVariantInput(this.props.masterProdId, this.props.props.context.request.apiSettings.channelId);
        getSelectedVariant(masterProductInput, this.props.props.context.actionContext).then(masterProduct => {
            this.setState({ prodName: masterProduct?.Name || '' });
        }).catch(e => console.error(e));
    }

    public render(): JSX.Element {
        let name = <React.Fragment>{this.props.variantName}</React.Fragment>;
        if (this.state.prodName !== '') {
            name = <React.Fragment><span className='product-master'>{this.state.prodName}</span> <span>-</span> <span className='product-variant'>{this.props.variantName}</span></React.Fragment>;
        }
        return name;
    }
}

export async function getMasterProduct(masterId: number, ctx: IActionContext): Promise<SimpleProduct | null> {
    const masterProductInput = new SelectedVariantInput(masterId, ctx.requestContext.apiSettings.channelId);
    return getSelectedVariant(masterProductInput, ctx);
}